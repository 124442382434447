import React from 'react'
import {Game, GameEng} from '../../data/data'
import Product from './Product'
import { motion } from "framer-motion"
import ProductEng from './ProductEng'

function OurProducts() {
  return (
    <motion.div
    initial={{y:-100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, y:0}}
viewport={{once: true}}
    className='flex flex-col mx-4 my-6  sm:px-40'>
<p className='flex justify-center text-3xl py-4 text-[#c2742b] font-semibold '>Our Products</p>
<div className=' text-xl py-4 text-white'>
We provide you with healthy, fresh fruits and tropical products that meet international standards and GLOBAL GAP certification.
</div>

<div  className=" grid md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 bg-slate-100">

  {GameEng && GameEng.map((data, index) => (<>
    <div key={index}>
      <ProductEng
      imgSrc={data?.imgSrc}
      nom={data?.nom}
      description={data?.description}
    />
    </div>
  </>))}
  
</div>



</motion.div>
  )
}

export default OurProducts