import React from 'react'
import { motion } from "framer-motion"
import cocoopen from "../../img/coco-ouvert.png"

function Finacceuil() {
  return (
    <div className='flex  items-center sm:px-40 bg-white mb-0'>
      <motion.div
        initial={{x:-100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
        className='flex w-fit '>
            <img src={cocoopen} alt="" className='h-[250px] w-[450px] sm:h-[400px] sm:w-[800px]  object-fill '/>
        </motion.div>
      <motion.div
        initial={{x:100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
        className='flex item-center justify-center ml-12'>
            <p className='text-[#0f100f]  text-base sm:text-2xl italic bg-[#f5b030de] px-10 py-3 rounded-2xl items-center justify-center mx-4 skew-x-12 max-w-7xl'>"Nous mettons à votre disposition des fruits et des produits tropicaux sains,frais, conformes aux normes internationales et certifiés GLOBAL GAP."</p>
        </motion.div>
    </div>
  )
}

export default Finacceuil