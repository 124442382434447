import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import acajou from "../img/acajou.jpg"
import { useNavigate } from 'react-router-dom';
import ananas from "../img/ananas.jpg"
import attieke from "../img/attieke.jpg"
import biscuit from "../img/biscuit.jpg"
import cacao from "../img/cacaco.jpg"
import ging from "../img/gingimbre.jpg"
import huile from "../img/huirle.jpg"
import igname from "../img/igname.jpg"
import karite from "../img/karite.jpg"
import manioc from "../img/manioc.jpg"
import chipsigna from "../img/chipsigna.jpg"
import arachides from "../img/arachides.jpg"
import chipsbana from "../img/chipsbanane.jpg"
import coco from "../img/noixcoco.jpg"
import mais from "../img/maiss.jpg"
import mangue from "../img/manguier.jpg"
import piment from "../img/piments.jpg"
import poivre from "../img/poivres.jpg"



const MyCarousel = () => {

    const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (e.g., 3 seconds)
    };
    
    const navigate = useNavigate()

    const produits = () => {
        navigate('/produits')
    }
   

  return (
<Slider {...settings} className="w-full space-x-4  sm:border-b-8 sm:border-b-white">
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={acajou} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
          </div>
          <div  onClick={produits} className='p-4 cursor-pointer sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]'>
        <img src={ananas} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={attieke} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={biscuit} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={cacao} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={ging} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={huile} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={igname} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={karite} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={manioc} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div  onClick={produits} className='p-4 cursor-pointer'>
        <img src={arachides} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={chipsbana} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={chipsigna} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={coco} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={mais} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={mangue} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
       <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={piment} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>
      <div onClick={produits} className='p-4 cursor-pointer'>
        <img src={poivre} alt="" className=' rounded-xl sm:w-[400px] sm:h-[260px] w-[350px] h-[70px]' />
      </div>

      

      {/* Add more slides as needed */}
    </Slider>
  )
}

export default MyCarousel