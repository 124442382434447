import React from 'react'
import LayoutEng from '../components/LayoutEng'
import ActualitesEng from './actualite/ActualitesEng'
import Topic from '../components/Topic'

function ActualiteEng() {
  return (
    <LayoutEng>
      <Topic />
      <ActualitesEng />  
    </LayoutEng> 
  )
}

export default ActualiteEng