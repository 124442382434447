import React from 'react'
import {Game} from '../../data/data'
import Product from './Product'
import { motion } from "framer-motion"

function NosProduits() {
  return (
      <motion.div
          initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}}
          className='flex flex-col mx-4 my-6  sm:px-40'>
      <p className='flex justify-center text-3xl py-4 text-[#c2742b] font-semibold '>Nos Produits</p>
      <div className=' text-xl py-4 text-white'>
        Nous mettons à votre disposition des fruits et des produits tropicaux sains,frais, conformes aux normes internationales et certifiés GLOBAL GAP
      </div>

      <div  className=" grid md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 bg-slate-100">
      
        {Game && Game.map((data, index) => (<>
          <div key={index}>
            <Product
            imgSrc={data?.imgSrc}
            nom={data?.nom}
            description={data?.description}
          />
          </div>
        </>))}
        
      </div>


      
    </motion.div>
  )
}

export default NosProduits