import React from 'react'
import Topic from '../components/Topic'
import Actualites from './actualite/Actualites'
import Layout from '../components/Layout'


function Actualite() {
  return (
    <Layout>
        <Topic />
        <Actualites />
    </Layout>
  )
}

export default Actualite