import React from 'react'
import Afric from '../img/afrique1.png'
import EngFleg from '../img/eng.png'
import HoldFlag from '../img/hol.png'
import FrancFlag from '../img/franc.png'
import GermFlag from '../img/germ.png'
import BelFlag from '../img/beg.png'
import CiFlag from '../img/ci.png'

function FooterEng() {
  return (
    <>
      <div className='hidden sm:flex border-t-4 border-t-white  bg-slate-950 px-4 sm:px-40 py-4 text-slate-300 items-center w-full justify-between sm:text-sm space-x-2 text-xs'>
      <div className='flex flex-col font-thin text-white space-y-2 '>
        <p className='text-[8px] sm:text-sm flex-wrap sm:flex-nowrap'>
        Head Office: 189 Southwark
        </p>
        <p className='text-[8px] sm:text-sm flex-wrap sm:flex-nowrap'>
          park Road, London SE16 3TX
        </p>
        <p className='text-[8px] sm:text-sm flex-wrap sm:flex-nowrap'>
          Website: https://www.scoopstogether.com
        </p>
        <p className='text-[8px] sm:text-sm flex-wrap sm:flex-nowrap'>
          E-mail: info@scoopstogether.com
        </p>
        <p className='text-[8px] sm:text-sm flex-wrap sm:flex-nowrap'>
          E-mail: stogetherinfos@gmail.com
        </p>
      </div>

      <div className='flex flex-col relative space-y-1 sm:space-y-3 text-[7px] sm:text-sm'>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={EngFleg} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white ' /></p>
          <p className='font-bold'>+44 79 84422550</p>
        </div>
    


        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={EngFleg} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white ' /></p>
          <p className='font-bold'>+44 79 60775370</p>
        </div>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={HoldFlag} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white' /></p>
          <p className='font-bold'> +31 6 24294083</p>
        </div>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={FrancFlag} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white' /></p>
          <p className='font-bold'> +33 751449849</p>
        </div>
        
      </div>

      <div className='flex flex-col relative space-y-1 sm:space-y-3 text-[7px] sm:text-sm'>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={GermFlag} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white mr-4' /></p>
          <p className='font-bold'> +49 176 21860200</p>
        </div>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={BelFlag} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white mr-4' /></p>
          <p className='font-bold'> +32 485 08 29 82</p>
        </div>

        <div className='flex space-x-3 relative'>
          <p className='mr-2 sm:mr-6'><img src={CiFlag} alt="" className='absolute w-3 h-3 sm:w-6 sm:h-6 rounded-full border border-white mr-4' /></p>
          <p className='font-bold'> +225 0748364168</p>
        </div>
      </div>

      <div className='hidden  sm:flex '>
        <p><img src={Afric} alt="Afrique" className=' w-20 h-20 sm:w-40 sm:h-40'/></p>
      </div>

      </div>
      

      <div className='flex sm:hidden border-t-8 border-t-white '>
        <div className='flex  bg-slate-950 mt-2 px-4 sm:px-40 py-2 text-slate-300 items-center w-full justify-between sm:text-sm space-x-2 text-xs'>
      <div className='flex flex-col font-thin text-white  '>
        <p className='text-[5px] sm:text-sm flex-wrap sm:flex-nowrap'>
          Head Office: 189 Southwark
        </p>
        <p className='text-[5px] sm:text-sm flex-wrap sm:flex-nowrap'>
          park Road, London SE16 3TX
        </p>
        <p className='text-[5px] sm:text-sm flex-wrap sm:flex-nowrap'>
          website: www.stogether.com
        </p>
        <p className='text-[5px] sm:text-sm flex-wrap sm:flex-nowrap'>
          E-mail: stogetherinfos@gmail.com
        </p>
      </div>

      <div className='flex flex-col relative  sm:space-y-3 text-[5px] sm:text-sm'>

        <div className='flex space-x-4 relative items-center'>
          <img src={EngFleg} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold '>+44 79 84422550</p>
        </div>
    


        <div className='flex space-x-4 relative items-center'>
          <img src={EngFleg} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'>+44 79 60775370</p>
        </div>

        <div className='flex space-x-4 relative items-center'>
          <img src={HoldFlag} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'> +31 6 24294083</p>
        </div>

        <div className='flex space-x-4 relative items-center'>
         <img src={FrancFlag} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'> +33 751449849</p>
        </div>
        
      </div>

      <div className='flex flex-col relative space-y-1 sm:space-y-3 text-[5px] sm:text-sm'>

        <div className='flex space-x-4 relative items-center'>
          <img src={GermFlag} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'> +49 176 21860200</p>
        </div>

        <div className='flex space-x-4 relative items-center'>
          <img src={BelFlag} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'> +32 485 08 29 82</p>
        </div>

        <div className='flex space-x-4 relative items-center'>
          <img src={CiFlag} alt="" className='absolute w-2 h-2 border border-white sm:w-6 sm:h-6 rounded-full mr-4' />
          <p className='font-bold'> +225 0748364168</p>
        </div>
      </div>

      <div className='hidden  sm:flex '>
        <p><img src={Afric} alt="Afrique" className=' w-20 h-20 sm:w-40 sm:h-40'/></p>
      </div>

    </div>
      </div>

      
    </>
  )
}

export default FooterEng