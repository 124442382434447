import Plant from '../../img/plantation.jpg'
import ananerais from '../../img/ananerais.jpg'
import cacaoyer from '../../img/cacaoyer.jpg'
import { motion } from "framer-motion"


function QuisommesnousEng() {
  return (
    <>

<div className='text-white hidden sm:flex'>

<div className='flex flex-col space-y-4 px-5 sm:px-40 justify-center'>
<p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>Who are we?</p>

  <div className='flex px-4 items-center '>

<motion.img
  initial={{x:-100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  src={Plant} alt="" className='  rounded-md w-[350px] h-[250px] object-cover' />

<motion.p
  initial={{x:100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  className='flex flex-col py-2 space-y-4 max-w-2xl'>
  <p className='font-bold uppercase  text-sky-600 text-lg sm:text-2xl'> Our history </p>
  <p className='text-justify mx-4  text-white text-xs sm:text-lg'>Motivated with the desire to bring more incomes to his parents and profit from their hard work and share with them his rich experience as a pan-African distributor of agricultural products, Mr CISSE Mohamed joined with the valiant farmers to create the simplified cooperative SCOOPS TOGETHER which means in English “bound Together” to enable them to benefit from the vast business opportunities it has garnered abroad.<br/>
SCOOPS TOGETHER involves coming together to add more value to farmers and find new opportunities for them in order to contribute to improving living conditions in rural areas.
</p>
</motion.p>
</div>


<div
className='flex px-4 items-center'>

<p className='flex flex-col py-2 space-y-4'>
  <p className='font-bold uppercase  text-sky-600 text-2xl'> About-Us </p>
  <p className='text-justify mx-4  text-white text-xs sm:text-lg'>We are a cooperative company specializing in the export of fresh, healthy tropical fruits and products, compliant with international standards and GLOBAL GAP certified. <br/>
The cooperative is made up with farmers keen to provide their customers with products that respect the planet and people. Our products come from all over Africa with West Africa as the epicenter.



</p>
</p>
<p className='flex py-6 justify-center items-center mr-4'><img src={ananerais} alt="" className='object-contain rounded-md  '/></p>
</div>


<div className='grid md:grid-cols-2 px-4'>
      <p className='flex py-6 justify-center items-center'><img src={cacaoyer} alt="" className='h-[350px] w-[500px] rounded-md' /></p>
      
<p className='flex flex-col py-2 space-y-4'>
  <p className='font-bold uppercase  text-sky-600 text-2xl'> Our ambition </p>
  <p className=' mx-4 text-white space-y-2  text-xs sm:text-lg'>
  We are aiming to :
    <ul className=' space-y-2'>
      <li className=' list-disc ml-4'>promote agroecological agriculture by avoiding the use of chemicals in order to market organic products,</li>
      <li className=' list-disc ml-4'>
      technically and economically support our cooperators by strengthening their cultivation capacities and promoting our label in compliance with national and international standards,
      </li>
      <li className=' list-disc ml-4'>
      meet the increasingly growing desire of our customers around the world by providing them with organic products.
      </li>
    </ul>
  </p>
</p>
</div>

</div>
</div>
{/*mobile screen*/}
<div className='text-white sm:hidden flex  flex-col justify-center relative px-4'>
<p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>
Who are we?
</p>

  <motion.img
  initial={{x:-100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  src={Plant} alt="" className='  rounded-md  object-cover' />

 <motion.p
  initial={{x:100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  className='flex flex-col py-2 space-y-4 '>
  <p className='font-bold uppercase  text-sky-600 text-lg '>
  Our history 
  </p>

  <p className='text-justify text-white text-xs'>
  Motivated with the desire to bring more incomes to his parents and profit from their hard work and share with them his rich experience as a pan-African distributor of agricultural products, Mr CISSE Mohamed joined with the valiant farmers to create the simplified cooperative SCOOPS TOGETHER which means in English “bound Together” to enable them to benefit from the vast business opportunities it has garnered abroad.<br/>
SCOOPS TOGETHER involves coming together to add more value to farmers and find new opportunities for them in order to contribute to improving living conditions in rural areas.
  </p>
</motion.p>

 <motion.p
  initial={{x:-100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  className='font-bold uppercase  text-sky-600 text-lg'>
 About-Us
</motion.p>
<motion.p
  initial={{x:100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  className='text-justify  text-white text-xs '>
 We are a cooperative company specializing in the export of fresh, healthy tropical fruits and products, compliant with international standards and GLOBAL GAP certified. <br/>
The cooperative is made up with farmers keen to provide their customers with products that respect the planet and people. Our products come from all over Africa with West Africa as the epicenter.
</motion.p>

<img
  src={ananerais} alt="" className='object-contain rounded-md py-4 ' />

<motion.p
  initial={{x:100, opacity:0, }}
transition={{duration:1.2}}
whileInView={{opacity:1, x:0}}
viewport={{once: true}}
  className='font-bold uppercase  text-sky-600 text-lg'>
  Our ambition
</motion.p>

<p className=' mx-4 text-white space-y-2  text-xs mb-4 '>
We are aiming to :
    <ul className=' space-y-2'>
      <li className=' list-disc ml-4'>promote agroecological agriculture by avoiding the use of chemicals in order to market organic products,</li>
      <li className=' list-disc ml-4'>
      technically and economically support our cooperators by strengthening their cultivation capacities and promoting our label in compliance with national and international standards,
      </li>
      <li className=' list-disc ml-4'>
      meet the increasingly growing desire of our customers around the world by providing them with organic products.
      </li>
    </ul>
  </p>

</div>


    </>
  )
}

export default QuisommesnousEng