import React from 'react'
import { motion } from "framer-motion"
import { useStateValue } from '../../StateProvider';
import { useNavigate } from 'react-router-dom';


function Product({id, imgSrc, nom, description}) {

  const [{basket}, dispatch] = useStateValue();
  const navigate = useNavigate();

  const addToBasket = () => {
    //dispatch the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        nom: nom,
        imgSrc: imgSrc,
        description: description,
     
      },
    });
    navigate('/contact');
  };

  
  return (
    <motion.div
    initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}} 
    onClick={addToBasket}
    className='flex flex-1 flex-col items-center  rounded-md  shadow-lg hover:shadow-white transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer border-2 border-[#00ADB5] bg-[#AAD8D3]'>
        <div className='bg-[#00ADB5] text-lg text-white font-bold w-full text-center py-2 mt-2'>
            {nom}
        </div>
       
            <img src={imgSrc} alt="" className='my-2 h-60 w-60 rounded-full border-4 p-2 bg-white border-orange-500' />
        
        <span className='px-4 text-xs text-slate-700'>
          {description}
        </span>
     
            
        <p 
        
        className='px-3 py-2 bg-[#191A19] text-white font-bold my-2 rounded-full shadow-slate-500'>Commandez</p>
        
    </motion.div>
  )
}

export default Product