import React from 'react'
import Topic from '../components/Topic'
import NosProduits from './produits/NosProduits'
import Layout from '../components/Layout'

function Produits() {
  return (
      <Layout>
          <Topic />
          <NosProduits />
    </Layout>
  )
}

export default Produits