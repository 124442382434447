import Plant from '../../img/plantation.jpg'
import ananerais from '../../img/ananerais.jpg'
import cacaoyer from '../../img/cacaoyer.jpg'
import { motion } from "framer-motion"

function Quisommesnous() {
  return (
    <>
      <div className='text-white hidden sm:flex'>

        <div className='flex flex-col space-y-4 px-5 sm:px-40 justify-center'>
      <p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>Qui sommes-nous?</p>
      
          <div className='flex px-4 items-center '>
        
        <motion.img
          initial={{x:-100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          src={Plant} alt="" className='  rounded-md w-[350px] h-[250px] object-cover' />
        
        <motion.p
          initial={{x:100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          className='flex flex-col py-2 space-y-4 max-w-2xl'>
          <p className='font-bold uppercase  text-sky-600 text-lg sm:text-2xl'> NOTRE HISTOIRE </p>
          <p className='text-justify mx-4  text-white text-xs sm:text-lg'>Né de sa volonté d’amener ses parents paysans à tirer profit leur dur labeur et mettre à leur disposition sa riche expérience de distributeur panafricain de produits agricoles, Mr CISSE Mohamed s’est joint aux vaillants agriculteurs pour créer la coopérative simplifiée SCOOPS TOGETHER qui signifie en anglais « Ensemble » pour les faire bénéficier des vastes opportunités d’affaires qu’il a engrangées à l’extérieur.<br/>
SCOOPS TOGETHER consiste à se mettre ensemble pour créer de la valeur ajoutée pour les agriculteurs et leur trouver de nouveaux débouchés afin contribuer à l’amélioration des conditions de vie dans le monde rural.
 </p>
        </motion.p>
      </div>


      <div
        className='flex px-4 items-center'>
        
        <p className='flex flex-col py-2 space-y-4'>
          <p className='font-bold uppercase  text-sky-600 text-2xl'> A propos de nous </p>
          <p className='text-justify mx-4  text-white text-xs sm:text-lg'>Nous sommes une Société coopérative spécialisée dans l’exportation de fruits et produits tropicaux frais, sains, conformes aux normes internationales et certifiés GLOBAL GAP. <br/>
La coopérative est composée d’agriculteurs soucieux de mettre à la disposition de leurs clients des produits respectueux de la planète et des hommes. Nos produits proviennent de toute l’Afrique avec pour épicentre l’Afrique de l’Ouest.

 </p>
        </p>
        <p className='flex py-6 justify-center items-center mr-4'><img src={ananerais} alt="" className='object-contain rounded-md  '/></p>
      </div>


      <div className='grid md:grid-cols-2 px-4'>
              <p className='flex py-6 justify-center items-center'><img src={cacaoyer} alt="" className='h-[350px] w-[500px] rounded-md' /></p>
              
        <p className='flex flex-col py-2 space-y-4'>
          <p className='font-bold uppercase  text-sky-600 text-2xl'> NOTRE ambition </p>
          <p className=' mx-4 text-white space-y-2  text-xs sm:text-lg'>
            Nous avons pour ambition de :
            <ul className=' space-y-2'>
              <li className=' list-disc ml-4'>promouvoir une agriculture agroécologique en évitant l’utilisation des produits chimiques afin de mettre sur marché des produits bio,</li>
              <li className=' list-disc ml-4'>
              accompagner techniquement et économiquement nos coopérateurs en renforçant leurs capacités culturales et faisant la promotion de notre label dans le respect des normes nationales et internationales,
              </li>
              <li className=' list-disc ml-4'>
              combler le souhait de plus en plus grandissant de nos clients à travers le monde en mettant à leur disposition des produits bio.
              </li>
            </ul>
          </p>
        </p>
      </div>

    </div>
      </div>
{/*mobile screen*/}
      <div className='text-white sm:hidden flex  flex-col justify-center relative px-4'>
        <p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>
          Qui sommes-nous?
        </p>
       
          <motion.img
          initial={{x:-100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          src={Plant} alt="" className='  rounded-md  object-cover' />
        
         <motion.p
          initial={{x:100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          className='flex flex-col py-2 space-y-4 '>
          <p className='font-bold uppercase  text-sky-600 text-lg '>
            NOTRE HISTOIRE
          </p>

          <p className='text-justify text-white text-xs'>
            SCOOPS TOGETHER est une société coopérative simplifiée qui signifie en anglais « Ensemble ».<br /> Cette coopérative est née de la volonté de vaillants agriculteurs de la cote d’ivoire et du Directeur Général  de GENERAL BUSINESS SERVICES, distributeur panafricain de produits agricoles depuis 2012.<br /> Elle consiste à se mettre ensemble pour créer de la valeur ajoutée pour les agriculteurs, à leur trouver de nouveaux débouchés et contribuer à la vitalité du monde rural.
          </p>
        </motion.p>

         <motion.p
          initial={{x:-100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          className='font-bold uppercase  text-sky-600 text-lg'>
          A propos de nous
        </motion.p>
        <motion.p
          initial={{x:100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          className='text-justify  text-white text-xs '>
          Nous sommes une Société coopérative simplifiée ivoirienne spécialisée dans l’exportation de fruits et produits tropicaux frais, sains, conforme aux normes internationales et certifies GLOBAL GAP.<br /> La coopérative est composée d’agriculteurs soucieux de proposer des produits  respectueux  de la planète et des hommes.
Nos produits proviennent de toute l’Afrique avec pour épicentre l’Afrique de l’Ouest.
 </motion.p>
       
        <img
          src={ananerais} alt="" className='object-contain rounded-md py-4 ' />
        
        <motion.p
          initial={{x:100, opacity:0, }}
        transition={{duration:1.2}}
        whileInView={{opacity:1, x:0}}
        viewport={{once: true}}
          className='font-bold uppercase  text-sky-600 text-lg'>
          NOTRE ambition
        </motion.p>

        <p className=' mx-4 text-white space-y-2  text-xs mb-4 '>
            Nous avons pour ambition de :
            <ul className=' space-y-2'>
              <li className=' list-disc ml-4'>Accompagner techniquement et économiquement nos coopérateurs en renforçant leurs capacités culturales et faisant la promotion de notre label dans le respect des normes nationales  et internationales,</li>
              <li className=' list-disc ml-4'>
              promouvoir une agriculture agroécologique en évitant l’utilisation des produits chimiques afin de mettre sur marché des produits bio,
              </li>
              <li className=' list-disc ml-4'>
              Combler le souhait de plus en plus grandissant de nos clients à travers le monde en mettant à leur disposition des produits bio.
              </li>
            </ul>
          </p>

      </div>
    </>
  )
}

export default Quisommesnous