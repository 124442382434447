import React from 'react'
import topic from '../img/top-pic1.jpg'

function Topic() {
  return (
     <div className='w-full'>
        <img src={topic} alt="" />
    </div>
  )
}

export default Topic