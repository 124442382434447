import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import franc from '../img/franc.png'
import { Link } from 'react-router-dom';

function HeaderEng() {
   const [nav, setNav] = useState(false);
  const [color, setColor] = useState('transparent');
  const [textColor, setTextColor] = useState('white');

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 90) {
        setColor('#ffffff');
        setTextColor('#000000 ');

      } else {
        setColor('transparent');
        setTextColor('#FFAB4C');

      }
    };
    window.addEventListener('scroll', changeColor);
  }, []);
  return (
       <div 
     style={{ backgroundColor: `${color}` }}className='fixed left-0 top-0 w-screen z-[10] ease-in duration-300  text-white font-thin px-6 py-2 items-center  flex justify-between'>
          
          <div style={{backgroundColor: `${color}` }}  className=
          'flex justify-between items-center w-screen ease-in duration-300 '
        >
              <Link to='/'>
                  <img src="/imagesaaa.png" alt="" className='h-[100px] w-[100px] object-fill rounded-md'/>
              </Link>
              
              <ul style={{ color: `${textColor}` }}   className='hidden sm:flex space-x-10'>

                <li className='border px-2 py-1 rounded-lg cursor-pointer  duration-300 relative font-extrabold text-xl hover:bg-slate-100 hover:text-zinc-950'>
            <Link to='/home' className='flex items-center'>
              <img src={franc} alt=""  className='w-3 h-3 mr-2'/>
                    <p className=''>Fr</p>
            </Link>
            
          </li>
            <li className=' cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]'>
                  <Link to='/'>
                    Welcome
                  </Link>
                </li>


                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]'>
                  <Link to='/aboutUs'>
                    Who are we?
                  </Link>
                </li>
        


                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]'>
                  <Link to='/products'>
                    Our Products
                  </Link>
                </li>

                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]'>
                  <Link to='/news'>
                  Our News
                  </Link>
                </li>

                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]'>
                  <Link to='/contactus'>
                  Contact us
                  </Link> 
                </li>
              </ul>
              
          </div>
          {/*Mobile button*/}
          <div onClick={handleNav} className='block sm:hidden z-10 '>
               {nav ? <AiOutlineClose size={20} style={{ color: `${textColor}` }} /> : <AiOutlineMenu size={20} style={{ color: `${textColor}` }} />}
              
          </div>
          <div
          className={
            nav
              ? 'sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300'
              : 'sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300'
          }
          >
              <ul className='flex flex-col items-center justify-center space-y-2'>
<li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>

            <Link to='/home' onClick={handleNav}  className='flex items-center'>
              <img src={franc} alt=""  className='w-3 h-3 mr-2'/>
                    <p className=''>Fr</p>
            </Link>
          </li>
          <li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/' onClick={handleNav} >
            Welcome
            </Link>
          </li>

          <li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/aboutUs' onClick={handleNav}>
            Who are we?
            </Link>
          </li>


          <li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/products' onClick={handleNav}>
            Our Products
            </Link>
          </li>
          
          <li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/news' onClick={handleNav}>
            Our News
            </Link>
          </li>

          <li className=' py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/contactus' onClick={handleNav} >
            Contact us
            </Link> 
          </li>
            
          </ul>
        </div>
    </div>
  )
}

export default HeaderEng