import coco from '../img/coco35.png'
import ananas from '../img/anana35.jpg'
import cashe from '../img/cashew.webp' 
import shea from '../img/shea.jpg'
import cacao from "../img/cacao.webp"
import ging from "../img/gingem.jpg"
import palm from "../img/palm.jpg"
import yam from "../img/yams.jpg"
import manioc from "../img/maniocs.jpg"
import plantain from "../img/plantain.jpg"
import biscuit from "../img/biscuit.jpeg"
import chipsbana from "../img/chipsbana.jpg"
import chipsigname from "../img/chipsigname.jpg"
import mais from "../img/mais.jpg"
import arachide from "../img/arachide.jpg"
import kola from "../img/kola.jpeg"
import cotton from "../img/cotton.webp"
import gombo from "../img/gombo.jpg"
import poivre from "../img/poivre.jpg"
import piment from "../img/piment.jpg"
import Mangue from "../img/Mangue.jpg"


const Game = [
    {
        id: 1,
        nom: "Noix de Coco",
        description: "La noix de coco offre aussi de nombreux atouts pour la santé. il est très riche en fibres alimentaires et contient du fer, du magnésium et du potassium.",
        imgSrc:coco,
    },
    {
        id: 2,
        nom: "Ananas",
        description: "L'ananas est composé de vitamines, dont la vitamine A et C (antioxydante) et un ingrédient actif phare : la bromélaïne, qui intervient dans la digestion des protéines.",
        imgSrc:ananas,
    },
     {
        id: 3,
        nom: "Anacarde ou Noix de cajou",
        description: "C'est un fruit sec oléagineux, riche en lipides mono-insaturés, de vitamines et de sels minéraux. C'est aussi une bonne source de protéines végétales et d'antioxydants ",
        imgSrc:cashe,
    },
        {
        id: 4,
        nom: "Beurre de karité",
        description: "C'est un beurre riche, crémeux et ultra-hydratant qui assouplit la peau, apaise la peau en cas d'éruptions cutanées, soigne les imperfections et Retarde les signes de l’âge",
        imgSrc: shea,
    },
    {
        id: 5,
        nom: "Cacao",
        description: "Le cacao est  très riche en flavonoïdes, en magnésium et en potassium. Il     protège contre les maladies cardio-vasculaires et joue rôle antistress.",
        imgSrc: cacao,
    },
      {
        id: 6,
        nom: "Gingembre",
        description: "Le gingembre améliore la digestion. Il contient des antioxydant et previent contre l’inflammation, contribue à la bonne santé articulaire, respiratoire, cardiovasculaire",
        imgSrc: ging,
    },
            {
        id: 7,
        nom: "Huile de palme ",
        description: "Elle contient naturellement de la vitamine K, de la vitamine E et des antioxydants  Elle contient une grande quantité de vitamine A qui protège le corps et la peau.",
        imgSrc: palm,
    },
    {
        id: 8,
        nom: "Igname",
        description: "L'igname est une source de fibres et de glucides complexes. Elle contient aussi de la vitamine B1 ou thiamine et de la vitamine B6. Ainsi que du manganèse et des antioxydants",
        imgSrc: yam,
    },
    {
        id: 12,
        nom: "Manioc",
        description: "Le manioc est une source de fibres et de glucides tel que l'amidon. Il est riche en Magnésium, en Potassium, en Vitamine C. Ainsi que du manganèse et des antioxydants",
        imgSrc: manioc,
    },
    {
        id: 9,
        nom: "Banane plantain",
        description: "La banane plantain contient de la vitamine C, de la vitamines B6 et B9. Elle est riche en Magnésium, en Potassium, en Vitamine C. Ainsi que du manganèse et des antioxydants",
        imgSrc: plantain,
       
    },
    {
        id: 10,
        nom: "Biscuit",
        description: "Le manioc est une source de fibres et de glucides tel que l'amidon. Il est riche en Magnésium, en Potassium, en Vitamine C. Ainsi que du manganèse et des antioxydants",
         imgSrc: biscuit,
    },
    {
        id: 11,
        nom: "Chips de banane",
        description: "La banane plantain contient de la vitamine C, de la vitamines B6 et B9. Elle est riche en Magnésium, en Potassium, en Vitamine C. Ainsi que du manganèse et des antioxydants",
        imgSrc: chipsbana,
       
    },
     {
        id: 13,
        nom: "Chips d'igname",
        description: "L'igname est une source de fibres et de glucides complexes. Elle contient aussi de la vitamine B1 ou thiamine et de la vitamine B6. Ainsi que du manganèse et des antioxydants",
        imgSrc: chipsigname,
       
    },
     {
        id: 14,
        nom: "Arachide",
        description: " L'arachide est riche en acides gras insaturés et une source de glucides, de fibres alimentaires et de protéines végétales. Il protège le système cardiovasculaire et des antioxydants.",
        imgSrc: arachide,
       
    },
       {
        id: 15,
        nom: "Maïs",
        description: " Le maïs est une excellente source de glucides et de fibres alimentaires. Il est sans gluten et possède un pouvoir antioxydant. Il favorise la santé cardiovasculaire.",
        imgSrc: mais,
       
    },
         {
        id: 16,
        nom: "Kola",
        description: "Le Kola est une excellente source   caféine, de polyphénols, d'acides phénols et de tanins ansi que d'un grand nombre d'antioxydantes.     ",
        imgSrc: kola,
       
    },
    {
        id: 17,
        nom: "Coton",
        description: "Le coton est une matière incontournable dans votre garde-robe et dans la maison. Il est hypoallergénique et sain pour la peau. Il est conseillé aux peaux sensibles",
        imgSrc: cotton,
       
    },
      {
        id: 19,
        nom: "Poivre",
          description: " Le poivre est une excellente source de pipérine et de molecules appelés Bolet poivré. C’est elle qui est responsable de la sensation de pseudo-chaleur.",
        imgSrc: poivre,
       
    },
          {
        id: 20,
        nom: "Gombo",
        description: " Le gombo est une excellente source   d'antioxydant, de fibres alimentaires. Il est très peu calorique et  une bonne source de vitamine K et de manganèse.",
        imgSrc: gombo,
       
    },
              {
        id: 21,
        nom: "Piment",
        description: "Il est riche en Vitamine A, Vitamine B6, Vitamine C, Vitamine E, Vitamine K, Vitamine PP,  du cuivre,  du fer du magnésium, du potassium et du sodium.  ",
        imgSrc: piment,
       
    },
              {
        id: 22,
        nom: "Mangue",
        description: " La mangue est une excellente source de fibres alimentaires et possède un pouvoir antioxydant. Il est pauvre en glycémique et possède des  Vertus anti-cancer. ",
        imgSrc: Mangue,
       
    },
]


const GameEng = [
    {
        id: 1,
        nom: "Coconut",
        description: "Coconut also offers many health benefits. it is very rich in dietary fiber and contains iron, magnesium and potassium.",
        imgSrc:coco,
    },
    {
        id: 2,
        nom: "Pineapple",
        description: "Pineapple is composed of vitamins, including vitamin A and C (antioxidant) and a key active ingredient: bromelain, which is involved in the digestion of proteins.",
        imgSrc:ananas,
    },
     {
        id: 3,
        nom: "Cashew nut",
        description: "It is an oilseed dried fruit, rich in monounsaturated lipids, vitamins and mineral salts. It is also a good source of plant-based proteins and antioxidants.",
        imgSrc:cashe,
    },
        {
        id: 4,
        nom: "Shea Butter",
        description: "It is a rich, creamy and ultra-hydrating butter that softens the skin, soothes the skin in case of breakouts, treats imperfections and delays the signs of aging",
        imgSrc: shea,
    },
    {
        id: 5,
        nom: "Cacoa",
        description: "Cocoa is very rich in flavonoids, magnesium and potassium. It protects against cardiovascular diseases and plays an anti-stress role.",
        imgSrc: cacao,
    },
      {
        id: 6,
        nom: "Ginger",
        description: "Ginger improves digestion. It contains antioxidants and prevents inflammation, contributes to good joint, respiratory and cardiovascular health.",
        imgSrc: ging,
    },
            {
        id: 7,
        nom: "Palm Oil",
        description: "It naturally contains vitamin K, vitamin E and antioxidants. It contains a large amount of vitamin A which protects the body and skin.",
        imgSrc: palm,
    },
    {
        id: 8,
        nom: "Yam",
        description: "Yam is a source of fiber and complex carbohydrates. It also contains vitamin B1 or thiamine and vitamin B6. As well as manganese and antioxidants",
        imgSrc: yam,
    },
    {
        id: 12,
        nom: "Cassava",
        description: "Cassava is a source of fiber and carbohydrates such as starch. It is rich in Magnesium, Potassium, Vitamin C. As well as manganese and antioxidants",
        imgSrc: manioc,
    },
    {
        id: 9,
        nom: "Plantain",
        description: "Plantain contains vitamin C, vitamins B6 and B9. It is rich in Magnesium, Potassium, Vitamin C. As well as manganese and antioxidants",
        imgSrc: plantain,
       
    },
    {
        id: 10,
        nom: "Biscuit",
        description: "Le manioc est une source de fibres et de glucides tel que l'amidon. Il est riche en Magnésium, en Potassium, en Vitamine C. Ainsi que du manganèse et des antioxydants",
         imgSrc: biscuit,
    },
    {
        id: 11,
        nom: "Banana chips",
        description: "Plantain contains vitamin C, vitamins B6 and B9. It is rich in Magnesium, Potassium, Vitamin C. As well as manganese and antioxidants.",
        imgSrc: chipsbana,
       
    },
     {
        id: 13,
        nom: "Yam chips",
        description: "Yam is a source of fiber and complex carbohydrates. It also contains vitamin B1 or thiamine and vitamin B6. As well as manganese and antioxidants",
        imgSrc: chipsigname,
       
    },
     {
        id: 14,
        nom: "Peanuts",
        description: " Peanuts are rich in unsaturated fatty acids and a source of carbohydrates, dietary fiber and plant proteins. It protects the cardiovascular system and provides antioxidants.",
        imgSrc: arachide,
       
    },
       {
        id: 15,
        nom: "Corn",
        description: " Corn is an excellent source of carbohydrates and dietary fiber. It is gluten-free and has antioxidant power. It promotes cardiovascular health.",
        imgSrc: mais,
       
    },
         {
        id: 16,
        nom: "Kola",
        description: "Kola is an excellent source of caffeine, polyphenols, phenol acids and tannins as well as a large number of antioxidants. ",
        imgSrc: kola,
       
    },
    {
        id: 17,
        nom: "Cotton",
        description: "Cotton is an essential material in your wardrobe and around the house. It is hypoallergenic and healthy for the skin. It is recommended for sensitive skin",
        imgSrc: cotton,
       
    },
      {
        id: 19,
        nom: "Pepper",
          description: " Pepper is an excellent source of piperine and molecules called pepper boletus. This is what is responsible for the feeling of pseudo-heat.",
        imgSrc: poivre,
       
    },
          {
        id: 20,
        nom: "Okra",
        description: " Okra is an excellent source of antioxidant, dietary fiber. It is very low in calories and a good source of vitamin K and manganese.",
        imgSrc: gombo,
       
    },
              {
        id: 21,
        nom: "Pepper",
        description: "It is rich in Vitamin A, Vitamin B6, Vitamin C, Vitamin E, Vitamin K, Vitamin PP, copper, iron, magnesium, potassium and sodium. ",
        imgSrc: piment,
       
    },
              {
        id: 22,
        nom: "Mango",
        description: " Mango is an excellent source of dietary fiber and has antioxidant power. It is low in glycemia and has anti-cancer properties. ",
        imgSrc: Mangue,
       
    },
]



export {Game, GameEng}