import React from 'react'

function HeroEng() {
  return (
    <div className='w-full mx-auto top-0'>
      {/*<div className="overlay"></div>*/}
      <div className="content  ">
      <h1 className=' absolute text-orange-600  text-2xl sm:text-5xl top-[20%] left-[5px]  sm:left-[10px] z-[4] font-bold  tracking-[10px] '>SCOOPS TOGETHER</h1>
          <h1 className=' absolute text-white  text-xl sm:text-5xl top-[35%]  z-[4] font-bold left-[5px] sm:left-[10px] md:flex-nowrap '>Organic and Fresh Products</h1>
          <p className=' absolute text-white  text-lg sm:text-2xl top-[45%]  z-[4]  left-[5px]  sm:left-[10px] '>A strong network of famers</p>
          <h1 className=' absolute text-orange-600 text-xl sm:text-5xl top-[55%]  z-[4] font-bold left-[5px]  sm:left-[10px] '>respectfull of the planet</h1>
      </div>
      <div className='absolute w-[1440px] h-[450px] bg-black/40 z-[2]' />
      <video 
        src="/video.mp4" 
        autoPlay loop muted 
        className='w-[1440px] h-[450px] object-cover'
      />

    
    </div>
  )
}

export default HeroEng