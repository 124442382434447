import React from 'react'
import LayoutEng from '../components/LayoutEng'
import HeroEng from './accueil/HeroEng'
import AcceuilEng from './accueil/AcceuilEng'
import FinaccueilEng from './accueil/FinaccueilEng'
import MyCarousel from '../components/MyCarousel'

function HomeEng() {
  return (
    
    <LayoutEng>
      <HeroEng />
      <AcceuilEng />
      <FinaccueilEng />
      <MyCarousel />
    </LayoutEng>
  )
}

export default HomeEng