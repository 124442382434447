import React from 'react'
import HeaderEng from './HeaderEng'
import FooterEng from './FooterEng'




function LayoutEng(props) {

    const { children } = props

  return (

    <>  

      <HeaderEng />
        <main className='flex flex-col justify-center '>
     
            {children}
        </main>
      <FooterEng />
    </>

  )
}

export default LayoutEng