import React from 'react'
import sia from '../../img/sia2.jpg'
import { motion } from "framer-motion";
import news2 from "../../img/new2.jpg";
import news1 from "../../img/news1.jpg";
import news from "../../img/news.jpg";

function ActualitesEng() {
  return (
    <div className=' items-center justify-center flex w-full bg-transparent text-4xl flex-col overflow-hidden scrollbar-hide'>
      
        <img src={sia} alt="SIA" className='w-[2000px]  ' />
        

		<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={news2}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
					At the closing of the doors of the 60th edition of the Paris International Agricultural Show (SIA 2024), on Sunday March 3, 2024, the Minister of State, Minister of Agriculture and Rural Development, KOBENAN Kouassi Adjoumani, has congratulated on the positive impact of Côte d'Ivoire.
						<br />
						
					</p>
				</motion.div>
			</div>



			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:mr-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
					According to Minister ADJOUMANI, this exhibition made it possible to effectively establish new contacts with partners who are ready to come and invest in Ivory Coast. “We spoke with technical and financial partners. We have convinced certain investors to come to Ivory Coast given the undeniable advantages that we offer them to settle in. I want to talk about the measures taken by the government to encourage investors to come to Ivory Coast,” he said..<br/>
						
					</p>
				</motion.div>
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={news1}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
			</div>
			

			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						
						src={news}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
					<b className="font-bold text-orange-700">Scoops-Together</b> took part in this important event for the world of agriculture. We would like to take this opportunity to thank the Ivorian authorities and more specifically our supervisory ministry for all the efforts made to allow us to be present at this important meeting.
					</p>
				</motion.div>
			</div>
    
    </div>
  )
}

export default ActualitesEng