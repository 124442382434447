import Layout from "./components/Layout";
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Quisommes from "./pages/Quisommes";
import Produits from "./pages/Produits";
import Actualite from "./pages/Actualite";
import Contact from "./pages/Contact";
import HomeEng from "./pages/HomeEng";
import QuisommesEng from "./pages/QuisommesEng";
import ProduitsEng from "./pages/ProduitsEng";
import ActualiteEng from "./pages/ActualiteEng";
import ContactEng from "./pages/ContactEng";

function App() {
  return (
    <div className=" bg-gradient-to-b from-green-950 to-slate-950 justify-center scrollbar-hide">
    
        <Routes>
          <Route path='/' element={<HomeEng />} />
          <Route path='/aboutUs' element={<QuisommesEng/>}/>
          <Route path='/products' element={<ProduitsEng />} />
          <Route path='/news' element={<ActualiteEng />} />
          <Route path='/contactus' element={<ContactEng />} />

          <Route path='/home' element={<Home />} />
          <Route path='/quisommesnous' element={<Quisommes />} />
          <Route path='/produits' element={<Produits />} /> 
          <Route path='/actualite' element={<Actualite />} />
          <Route path='/contact' element={ <Contact />}/>
        </Routes>
      


    </div>
  );
}

export default App;
