import React from 'react'
import Topic from '../components/Topic'
import Quisommesnous from './qui/Quisommesnous'
import Layout from '../components/Layout'

function Quisommes() {
  return (
    <Layout>
        <Topic />
        <Quisommesnous />
    </Layout>
  )
}

export default Quisommes