import React from 'react'
import LayoutEng from '../components/LayoutEng'
import Contactus from './contact/Contactus'
import Topic from '../components/Topic'

function ContactEng() {
  return (
    <LayoutEng>
    <Topic />
    <Contactus/>
    </LayoutEng>
  )
}

export default ContactEng