import React from "react";
import { motion } from "framer-motion";
import Presi from "../../img/outlet.png";
import Presi1 from "../../img/afrivivre.png";
import Presi3 from "../../img/expo-anacarde.png";
import { Link } from "react-router-dom";




function Accueil() {
	return (
		<>
			<div className="flex flex-col items-center flex-1 px-4 sm:mx-40 py-4 bg-white mt-4 rounded-md">
				<p className=" text-orange-700 uppercase flex flex-1 justify-center text-3xl font-bold my-2">
					la compagne d'anacharde 2024 est ouverte
				</p>
				<div className="flex flex-1 items-center">
					<motion.div 
          initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
          className=" font-extralight flex flex-col w-2/3">
          Scoops-Together, depuis son retour du 60ème édition du Salon International de l’Agriculture de Paris (SIA 2024), s'est dotté des moyens nécessaires pour être déjà sur le terrain afin de prendre une part importante dans la campagne de cette année.<br/>
          Il faut noter que les projections pour la campagne 2024 sont prometteuses, avec une production de noix brutes de cajou estimée à 1,25 million de tonnes, soit une hausse attendue de 5% par rapport à 2023, ainsi qu'une augmentation prévisionnelle de 20% du niveau de transformation, atteignant 320 000 tonnes en Côte d'Ivoire.
		  <Link to='/contact' className=" text-orange-700 font-bold text-xl cursor-pointer mt-4">Contactez-nous maintenant</Link>
          </motion.div>
          <motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 w-1/3 justify-center">
					<img
						src={Presi3}
						alt=""
						className=" sm:w-[180px] sm:h-[210px] w-[80px] h-[110px] "
					/>
				</motion.div>
				</div>
			</div>

			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={Presi}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[120px] h-[150px] "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
						<b className="font-bold text-orange-700">Scoops-Together </b>est un
						mot d’origine anglaise qui signifie « Ensemble ». La société
						coopérative simplifiée est née de la volonté de plusieurs vaillants
						agriculteurs et de la société GENERAL BUSINESS SERVICES,
						distributeur panafricain de produits agricoles depuis 2012.
					</p>
				</motion.div>
			</div>
			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col  ">
					<p className="mt-3  text-xs sm:text-lg font-thin text-white">
						<b className="font-bold text-orange-700">Scoops-Together </b> est
						une Société ivoirienne spécialisée dans l’exportation de fruits et
						produits tropicaux frais, sains, conformes aux normes
						internationales et certifiés GLOBAL GAP.
						<br /> C'est une coopérative composée d'un réseau d’agriculteurs
						soucieux de proposer des produits respectueux de la planète.
					</p>
				</motion.div>

				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 justify-center">
					<img
						src={Presi1}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[150px] h-[180px] "
					/>
				</motion.div>
			</div>
		</>
	);
}

export default Accueil;
