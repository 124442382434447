import React from 'react'
import Hero from '../pages/accueil/Hero'
import Accueil from './accueil/Acceuil'
import Finacceuil from './accueil/Finaccueil'
import MyCarousel from '../components/MyCarousel'
import Layout from '../components/Layout'

function Home() {
  return (
    <Layout>
      <Hero />
      <Accueil />
      <Finacceuil />
      <MyCarousel />
    </Layout>

  )
}

export default Home