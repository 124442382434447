import React from "react";
import { motion } from "framer-motion";
import Presi from "../../img/outlet.png";
import Presi1 from "../../img/afrivivre.png";
import Presi3 from "../../img/expo-anacarde.png";
import { Link } from "react-router-dom";

function AcceuilEng() {
	return (
		<>
			<div className="flex flex-col items-center flex-1 px-4 sm:mx-40 py-4 bg-white mt-4 rounded-md">
				<p className=" text-orange-700 font-bold uppercase flex flex-1 justify-center text-3xl ">
					Cashew nut campaign is now opened !
				</p>
				<div className="flex flex-1 items-center">
					<motion.div 
          initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
          className=" font-extralight flex flex-col w-2/3 mt-4">
          Since our return from the 60th Agriculture International Exhibition (SIA 2024), Scoops-Together is focusing on the raw Cashew nut campaign in order to play a key role to supply a huge quantity of raw Cashew nut. <br/>
          Note that for this campaign, the experted production will increase upto 20%, so the national production will hit up 1,25 million tones of raw cashew nut, and the country is expecting the local transformation of 320 000 tones.<br/>
          
		  <p className="font-bold ">Scoop-Together is now ready with the high quality and quantity and can spread world wide any quantity of raw cashew nut you may need.</p>
		  
		  <Link to='/contactus' className=" text-orange-700 font-bold text-xl cursor-pointer mt-4">Contact-us Now</Link>
          
          
          </motion.div>
          <motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 w-1/3 justify-center">
					<img
						src={Presi3}
						alt=""
						className=" sm:w-[180px] sm:h-[210px] w-[80px] h-[110px] "
					/>
				</motion.div>
				</div>
			</div>
			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={Presi}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[120px] h-[150px] "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
						<b className="font-bold text-orange-700">Scoops-Together </b>is also
						an english word that stands for « bound Together ». This company has
						been Created under the leadership of Mr Cisse Mohamed who's willing
						to share his experience with valiant farmers and give them the
						opportunity to supply international market to increase their income.
					</p>
				</motion.div>
			</div>
			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col  ">
					<p className="mt-3  text-xs sm:text-lg font-thin text-white">
						<b className="font-bold text-orange-700">Scoops-Together </b> is an
						Ivorian company specializing in the export of fresh, healthy
						tropical fruits and products, complying with international standards
						and GLOBAL GAP. <br />
						It is a cooperative made up of a network of farmers concerned with
						offering products that respect the planet.
					</p>
				</motion.div>

				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 justify-center">
					<img
						src={Presi1}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[150px] h-[180px] "
					/>
				</motion.div>
			</div>
		</>
	);
}

export default AcceuilEng;
