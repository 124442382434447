import React from 'react'
import Topic from '../components/Topic'
import Contacts from './contact/Contacts'
import Layout from '../components/Layout'


function Contact() {
  return (
    <Layout>
        <Topic />
        <Contacts />
    </Layout>
  )
}

export default Contact