import React from 'react'
import LayoutEng from '../components/LayoutEng'
import Topic from '../components/Topic'
import OurProducts from './produits/OurProducts'

function ProduitsEng() {
  return (
    <LayoutEng>
      <Topic />
      <OurProducts />
    </LayoutEng>
  )
}

export default ProduitsEng