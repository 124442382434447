import React from "react";
import sia from "../../img/sia2.jpg";
import { motion } from "framer-motion";
import news2 from "../../img/new2.jpg";
import news1 from "../../img/news1.jpg";
import news from "../../img/news.jpg";



function Actualites() {
	return (
		<div className=" items-center justify-center flex w-full bg-transparent text-4xl flex-col overflow-hidden scrollbar-hide">
			<img src={sia} alt="SIA" className="w-[2000px]  " />
			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={news2}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
						À la fermeture des portes de la 60ème édition du Salon International
						de l’Agriculture de Paris (SIA 2024), le dimanche 03 mars 2024, le
						ministre d’État, ministre de l’Agriculture et du Développement
						rural, KOBENAN Kouassi Adjoumani, s'est félicité des retombées
						positives dudit salon pour la Côte d’Ivoire.
						<br />
						
					</p>
				</motion.div>
			</div>



			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:mr-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
					Selon le ministre ADJOUMANI, ce salon a permis de tisser
						effectivement de nouveaux contacts avec des partenaires qui sont
						prêts à venir investir en Côte d’Ivoire. « Nous avons échangé avec
						des partenaires techniques et financiers. Nous avons convaincu
						certains investisseurs à venir en Côte d’Ivoire au regard des atouts
						indéniables que nous leur offrons pour s’installer. Je veux parler
						des mesures prises par le gouvernement pour inciter les
						investisseurs à venir en Côte d’Ivoire », a-t-il fait savoir.
						<br />
						<br />
						
					</p>
				</motion.div>
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						src={news1}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
			</div>
			

			<div className="flex items-center flex-1 px-4 sm:px-40 py-8">
				<motion.div
					initial={{ x: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1">
					<img
						
						src={news}
						alt=""
						className=" sm:w-[320px] sm:h-[350px] w-[130px] h-[160px] object-cover"
					/>
				</motion.div>
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, x: 0 }}
					viewport={{ once: true }}
					className="flex flex-1 flex-col sm:-ml-20 ">
					<p className="  text-xs sm:text-xl font-thin text-white">
					<b className="font-bold text-orange-700">Scoops-Together </b>a pris
						part à cet important évènement pour le monde de l'agriculture. Nous
						voudrions saisir cette opportunité pour remercier les autorités
						ivoiriennes et plus précisément notre ministère de tutelle pour tous
						les efforts fournis pour nous permettre d'être présent à cette
						importante rencontre.
						
					</p>
				</motion.div>
			</div>
			

			
		</div>
	);
}

export default Actualites;
