import React, { useRef } from 'react';
import { motion } from "framer-motion"
import { useState } from 'react'
import emailjs from '@emailjs/browser'
import { Toaster, toast } from "react-hot-toast";



function Contactus() {


  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  
  


  const handleSubmit = (e) => {
    e.preventDefault()
    //keys and services
    const templateId ='template_2m8bpjv'
    const publicKey = 'vRKpOxPaAllPvKD00'
    const serviceId = 'service_d6yabjl' 

    //create new object

    const templateParams ={
      to_name:'Scoops Together',
      from_name:name,
      from_firstName:firstName,
      from_email:email,
      from_phone:phone,
      subject:subject,
      message:message,
    }
    
    //send theEmail
    emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then((response)=>{
      console.log('Email envoyé avec succès', response)
      setEmail('')
      setFirstName('')
      setMessage('')
      setPhone('')
      setName('')
      setSubject('')
    })
    .catch((error)=>{
      console.error('Error sending', error)
    })

    //////////
    const refreshToast = toast.loading("Votre message a été envoyé! notre équipe vous contactera.");
			toast.success("Votre message a été envoyé! notre équipe vous contactera", {
				id: refreshToast,
			});
  }

  

  return (
    <motion.div
      initial={{ y: -100, opacity: 0, }}
      transition={{ duration: 1.2 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className='flex flex-col px-6 sm:px-40 items-center justify-center'
    >

      <p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>
        Contact-us
      </p>
      <Toaster />
      <div className='flex-1 items-center'>
        <form className='' onSubmit={handleSubmit}>

          <div className='flex space-x-2'>
            <p className='flex-1'>
              <input
                type="text"
                placeholder="Your name"
                className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                value={name}
            onChange={(e) => setName(e.target.value)}
              />
            </p>

            <p className='flex-1'>
              <input
                type="text"
                placeholder="Your first name"
                className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </p>

          </div>

          <div className='flex space-x-2'>
            <p className='flex-1'>
              <input
                type="email"
                placeholder="Votre email"
                className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>

            <p className='flex-1'>
              <input
                type="text"
                placeholder="Your phone number"
                className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </p>

          </div>

          <div className='flex space-x-2'>
            <p className='flex-1'>
              <input
                type="text"
                placeholder="subject"
                className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </p>



          </div>


          <textarea name="" id="" cols="30" rows="10" 
          className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md" 
          placeholder='Your Message' 
          value={message}
          onChange={(e) => setMessage(e.target.value)} >
          </textarea>

          <button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white" >
            Send my Message
          </button>
        </form>

      </div>

    </motion.div>
  )
}

export default Contactus