import React from 'react'
import LayoutEng from '../components/LayoutEng'
import Topic from '../components/Topic'
import QuisommesnousEng from './qui/QuisommesnousEng'

function QuisommesEng() {
  return (
    <LayoutEng>
    <Topic />
    <QuisommesnousEng />
</LayoutEng>
  )
}

export default QuisommesEng